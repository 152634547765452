import { PortableTextComponent } from '/features/buildingBlocks/PortableTextComponent'
import { HeadingXs } from '/features/buildingBlocks/Heading'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Accordion.css'

import chevronDown from '/images/icons/chevron-down.raw.svg'

export function Accordion({ items }) {
  const [activeCard, setActiveCard] = React.useState({ id: undefined, active: false })

  return (
    <ul className={styles.component}>
      {items.map((item, i) => (
        <ItemCard
          key={i}
          id={i}
          title={item.question}
          text={item.answer}
          active={i === activeCard.id && activeCard.active}
          onOpenChange={handleOpenChange}
        />
      ))}
    </ul>
  )

  function handleOpenChange(id) {
    setActiveCard(prevCard => ({ id, active: prevCard.id === id ? !activeCard.active : true }))
  }
}

function ItemCard({ id, title, text, active, onOpenChange }) {
  return (
    <li className={styles.componentItemCard}>

      <button onClick={() => onOpenChange(id)} data-x='click-on-faq-card' className={cx(styles.button, active && styles.isActive)}>
        {title && <HeadingXs h='3' layoutClassName={styles.headingLayout}>{title}</HeadingXs>}
        <div className={styles.iconWrapper}>
          <span className={cx(styles.iconContainer, active && styles.isActive)}>
            <Icon icon={chevronDown} layoutClassName={styles.iconLayout} />
          </span>
        </div>
      </button>

      <div className={cx(styles.answerContainer, active && styles.isActive)}>
        <PortableTextComponent value={text} layoutClassName={styles.portableTextLayout} />
      </div>

    </li>
  )
}
